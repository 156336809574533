import * as React from 'react';
import * as translation from '../../../locale/translation.json';


interface IErrorProps extends React.ClassAttributes<any> {
    toggleModal: () => void;
    errorMessage: string;
}

interface IErrorState extends React.ClassAttributes<any> {
}

class ErrorPopup extends React.Component<IErrorProps, IErrorState> {
    constructor(props) {
        super(props);
    }

    public render() {
        const { toggleModal, errorMessage } = this.props;
        const locale = translation.nb.translation;

        return (
            <div className="popup-overlay popup-window">
                <div className="popup">
                    <div className="text-block">
                        <h1>{ locale.title.error }</h1>
                        <p>{ errorMessage }</p>
                        <button className="ok-button" onClick={ (e) => toggleModal() } >OK</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPopup;
