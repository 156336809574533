import * as React from 'react';
import Spinner from 'components/spinner/Spinner';

class DefaultPage extends React.Component<any> {
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className="default-page-wrap">
                <div className="preload-page-logo">
                    <Spinner />
                    <img src="./images/tidypay-log-white-transparent.png" alt="TidypayGO"/>
                </div>
            </div>
        );
    }
}
export default DefaultPage;
