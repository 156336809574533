import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { Route, HashRouter } from 'react-router-dom';
import thunk from 'redux-thunk';
import ReactGA from 'react-ga';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// Reducer
import rootReducer from '../src/core/reducer';
// Ui
import renderRoutes from '../src/core/ui/renderRoutes';
import { routes } from '../src/core/ui/routes';


const virtualPath = document.baseURI.replace(/^(https?:\/\/[^\/]+)?(.*)\/$/, '$2');
const historyOptions = { basename: `${virtualPath}` };
const history = createBrowserHistory(historyOptions);

const stage = process.env.REACT_APP_STAGE;
const tagGA = process.env.REACT_APP_TAG_GA;
ReactGA.initialize(tagGA);
ReactGA.pageview(window.location.hash);

let releaseStage = 'DEV';
if (stage === 'qa') {
    releaseStage = 'QA';
} else if (stage === 'v2') {
    releaseStage = 'PROD';
}

if (location.hostname === 'localhost') {
    releaseStage = 'TEST';
}

Bugsnag.start({
    apiKey: '7398a896b9bd90f4bd36144f05dee69c',
    plugins: [
        new BugsnagPluginReact(),
    ],
    releaseStage,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

// Initialize google analytics page view tracking
history.listen((location) => {
    const merchant = localStorage ? localStorage.getItem('merchantQuery') : undefined;
    ReactGA.set({
        page: location.hash,
        merchant,
    });
    ReactGA.event({
        category: 'page',
        action: 'change_location',
        label: merchant,
    });
    ReactGA.pageview(location.href); // Record a pageview for the given page
});


declare const window: Window & { devToolsExtension: any, __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any };
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))(createStore)(rootReducer);

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={ store }>
            <HashRouter store={ store } history={ history } >
                { renderRoutes(routes) }
            </HashRouter>
        </Provider>
    </ErrorBoundary>,
  document.getElementById('the-app'),
);
