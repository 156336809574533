import { SUB_QUANTITY, ADD_QUANTITY, ADD_COMMENT, RECEIVE_STORED_PRODUCT, UPDATE_PRODUCT, REMOVE_FROM_CART } from 'company/constants';
import update from 'immutability-helper';
import OrderProduct from 'company/model/OrderProduct';
import CompanyService from 'company/service/CompanyService';
const currency = require('currency.js');

const initialState: {
    items: OrderProduct[],
    addedItems: OrderProduct[],
    totalIncVat: number,
    totalExVat: number,
} = {
    items: [],
    addedItems: [],
    totalIncVat: 0,
    totalExVat: 0,
};

const cartReducer = (state = initialState, action) => {

    switch (action.type) {

        case RECEIVE_STORED_PRODUCT: {
            const addedItems = action.defaultStateProductList.filter((item) => item.quantity > 0);
            let totalIncVat = 0;
            let totalExVat = 0;
            // total + (addedItem.price * addedItem.quantity) with using currency
            addedItems.map((addedItem) => {
                totalIncVat = currency(totalIncVat).add(
                        currency(addedItem.priceIncVat).multiply(addedItem.quantity).value,
                    ).value;
                totalExVat = currency(totalExVat).add(
                    currency(addedItem.priceExVat).multiply(addedItem.quantity).value,
                ).value;
            });

            CompanyService.setStorageCartData(addedItems, totalIncVat, totalExVat);

            return update(state, {
                items: { $set: action.defaultStateProductList },
                addedItems: { $set: addedItems },
                totalIncVat: { $set: totalIncVat },
                totalExVat: { $set: totalExVat },
            });
        }

        case ADD_QUANTITY: {
            const addedItem = state.items.find((item) => item.id === action.id);
            const newTotalIncVat = currency(state.totalIncVat).add(addedItem.priceIncVat).value;
            const newTotalExVat = currency(state.totalExVat).add(addedItem.priceExVat).value;

            let addedItems = [];

            // check if the action id exists in the addedItems
            const existed_item = state.addedItems.find((item) => item.id === action.id);

            if (existed_item) {
                addedItem.quantity += 1;
                addedItems = [...state.addedItems];
            } else {
                addedItem.quantity += 1;
                addedItems = [...state.addedItems, addedItem];
            }
            CompanyService.setStorageCartData(addedItems, newTotalIncVat, newTotalExVat);

            return {
                ...state,
                addedItems,
                totalIncVat: newTotalIncVat,
                totalExVat: newTotalExVat,
            };
        }

        case SUB_QUANTITY: {
            const addedItem = state.items.find((item) => item.id === action.id);
            const newTotalIncVat = currency(state.totalIncVat).subtract(addedItem.priceIncVat).value;
            const newTotalExVat = currency(state.totalExVat).subtract(addedItem.priceExVat).value;

            let addedItems = [];

            // if the quantity - 1 == 0 then it should be removed
            if (addedItem.quantity === 1) {
                if (addedItem.isChangeablePrice) {
                    addedItem.priceIncVat = 0;
                    addedItem.priceExVat = 0;
                }
                addedItem.quantity -= 1;
                addedItem.comment = null;
                const new_items = state.addedItems.filter((item) => item.id !== action.id);
                addedItems = new_items;

            } else {
                addedItem.quantity -= 1;
                addedItems = [...state.addedItems];
            }

            CompanyService.setStorageCartData(addedItems, newTotalIncVat, newTotalExVat);

            return {
                ...state,
                addedItems,
                totalIncVat: newTotalIncVat,
                totalExVat: newTotalExVat,
            };
        }

        case UPDATE_PRODUCT: {
            const updatedItem = state.items.find((item) => item.id === action.id);

            // prevTotalCustomPrice (-IncVat, -ExVat) = [previous price, before updating input]*quantity
            const prevTotalCustomPriceIncVat = currency(updatedItem.priceIncVat).multiply(updatedItem.quantity).value;
            const prevTotalCustomPriceExVat = currency(updatedItem.priceExVat).multiply(updatedItem.quantity).value;

            // redefine prices
            updatedItem.priceIncVat = currency(action.price).value;
            updatedItem.priceExVat = currency(action.price).divide(1 + (updatedItem.vatPercent / 100)).value;

            // newTotalCustomPrice (-IncVat, -ExVat) = [new price, after redefine prices from new input value]*quantity
            const newTotalCustomPriceIncVat = currency(updatedItem.priceIncVat).multiply(updatedItem.quantity).value;
            const newTotalCustomPriceExVat = currency(updatedItem.priceExVat).multiply(updatedItem.quantity).value;
            const addedItems = [...state.addedItems];

            // for updating total [newTotalCustomPrice (-IncVat, -ExVat)]
            // we should subtract total amount for previous price [prevTotalCustomPrice (-IncVat, -ExVat)] and
            // add total amount for redefined price [newTotalCustomPrice (-IncVat, -ExVat)]
            const newTotalIncVat = currency(state.totalIncVat).subtract(prevTotalCustomPriceIncVat).add(newTotalCustomPriceIncVat).value;
            const newTotalExVat = currency(state.totalExVat).subtract(prevTotalCustomPriceExVat).add(newTotalCustomPriceExVat).value;

            CompanyService.setStorageCartData(addedItems, newTotalIncVat, newTotalExVat);
            // TODO: add max price for input

            return{
                ...state,
                addedItems,
                totalIncVat: newTotalIncVat,
                totalExVat: newTotalExVat,
            };
        }

        case REMOVE_FROM_CART: {
            const itemToRemove = state.items.find((item) => item.id === action.id);
            const new_items = state.addedItems.filter((item) => item.id !== action.id);

            // total - (itemToRemove.price * itemToRemove.quantity) with using currency lib
            const newTotalIncVat = currency(state.totalIncVat).subtract(
                currency(itemToRemove.priceIncVat).multiply(itemToRemove.quantity).value,
            ).value;
            const newTotalExVat = currency(state.totalExVat).subtract(
                currency(itemToRemove.priceExVat).multiply(itemToRemove.quantity).value,
            );


            itemToRemove.quantity = 0;
            itemToRemove.priceIncVat = 0;
            itemToRemove.priceExVat = 0;
            itemToRemove.comment = null;
            CompanyService.setStorageCartData(new_items, newTotalIncVat, newTotalExVat);
            return{
                ...state,
                addedItems: new_items,
                totalIncVat: newTotalIncVat,
                totalExVat: newTotalExVat,
            };
        }

        case ADD_COMMENT: {
            const itemComment = state.addedItems.find((item) => item.id === action.id);
            itemComment.comment = action.comment;

            CompanyService.setStorageCartData(state.addedItems, state.totalIncVat, state.totalExVat);

            return {
                ...state,
                addedItems: [...state.addedItems],
            };
        }

        default:
            return state;
    }

};

export default cartReducer;
