export const RECEIVE_COMPANY = 'RECEIVE_COMPANY';
export const RECEIVE_ERROR = 'RECEIVE_ERROR';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
export const RECEIVE_STORED_PRODUCT = 'RECEIVE_STORED_PRODUCT';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const ADD_COMMENT = 'ADD_COMMENT';


export const COUNTRY_CODE_SELECT_DEFAULT_VALUE = 'NO';
