import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as translation from '../../../locale/translation.json';
const currency = require('currency.js');
import classnames from 'classnames';

import { addQuantity, subtractQuantity, updateProduct, removeFromCart, addCommentAction } from 'company/action/public';
import OrderProduct from 'company/model/OrderProduct';
import Product from 'company/model/Product';
import CompanyService from 'company/service/CompanyService';

interface IProductsPageProps extends React.ClassAttributes<any> {
    addQuantity: (id) => void;
    subtractQuantity: (id) => void;
    updateProduct: (id, price) => void;
    removeFromCart: (id) => void;
    addCommentAction: (comment, id) => void;
    productItem: Product;
    productIndex: number;
    order: OrderProduct[];
    currentItemOrderInfo: {
        quantity: number,
        comment: string,
        isChangeablePrice: boolean,
        priceIncVat: number,
    };
    togglePopup: (state, initialHeight) => void;
}

interface IProductsPageState extends React.ClassAttributes<any> {
    isToggleCustomPrice: boolean;
    customPrice: number;
    errorCustomPrice: boolean;
    isImagePopupOpen: boolean;
}

class ProductItem extends React.Component<IProductsPageProps, IProductsPageState> {
    private myCommentFormRef: any;
    constructor(props) {
        super(props);
        this.myCommentFormRef = React.createRef();

        this.state = {
            isToggleCustomPrice: false,
            customPrice: null,
            errorCustomPrice: false,
            isImagePopupOpen: false,
        };

        this.decrement = this.decrement.bind(this);
        this.increment = this.increment.bind(this);
        this.handleComment = this.handleComment.bind(this);
        this.getCustomPrice = this.getCustomPrice.bind(this);
        this.toggleImagePopup = this.toggleImagePopup.bind(this);
    }

    private decrement(id) {
        const { currentItemOrderInfo } = this.props;
        this.props.subtractQuantity(id);
        if (currentItemOrderInfo.isChangeablePrice && (!this.state.customPrice || currentItemOrderInfo.quantity === 1)) {
            this.myCommentFormRef.reset();
            this.setState({
                isToggleCustomPrice: false,
            });
        }
    }

    private increment(prod) {
        const { currentItemOrderInfo } = this.props;
        if (!this.state.isToggleCustomPrice || (currentItemOrderInfo.isChangeablePrice && this.state.customPrice > 0)) {
            this.props.addQuantity(prod.id);
            if (prod.unitPriceExVat === 0) {
                this.setState({
                    isToggleCustomPrice: true,
                });
            }
        }
    }

    private handleComment(e, id, item) {
        item.comment = e.target.value;
        this.props.addCommentAction(e.target.value, id);
    }

    private getCustomPrice(e, item) {
        const customPrice = currency(e.target.value).value;
        if (!customPrice || customPrice < 1) {
            this.props.removeFromCart(item.id);
            this.myCommentFormRef.reset();

            if (customPrice > 0 && customPrice < 1) {
                this.setState({
                    errorCustomPrice: true,
                });
            }
            this.setState({
                isToggleCustomPrice: false,
                customPrice: null,
            });
            return;
        }
        this.setState({
            customPrice,
            errorCustomPrice: false,
        });
        this.props.updateProduct(item.id, customPrice);
    }

    private toggleImagePopup(e, productItem) {
        if (productItem.imageLocation) {
            const initialHeight = !this.state.isImagePopupOpen ? e.pageY : null;
            this.props.togglePopup(!this.state.isImagePopupOpen, initialHeight);
            this.setState({
                isImagePopupOpen: !this.state.isImagePopupOpen,
            });
        }
    }

    public render() {
        const { productItem, currentItemOrderInfo } = this.props;
        let locale = translation.nb.translation;
        if (navigator.language.substr(0, 2).includes("en")) {
            locale = translation['en-GB'].translation;
        } else if (navigator.language.substr(0, 2).includes("fi")) {
            locale = translation['fi'].translation;
        } else if (navigator.language.substr(0, 2).includes("sv")) {
            locale = translation['sv'].translation;
        } else if (navigator.language.substr(0, 2).includes("da")) {
            locale = translation['da'].translation;
        }
        const descriptionHTML = productItem.desc ? productItem.desc.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br/>') : '-';
        const descArray = descriptionHTML.split('<br/>');

        return (
            <div className={ classnames('product', { 'product-custom-price-error': this.state.errorCustomPrice }) }>
                <div className={ classnames('popup-overlay', { 'popup-active': !!this.state.isImagePopupOpen }) }
                     onClick={ (e) => this.toggleImagePopup(e, productItem) }>
                    <div className="popup">
                        <button className="close count-button" />
                        <div className="popup-image-wrap" style={ { backgroundImage: 'url(' + productItem.imageLocation + ')' } } />
                    </div>
                </div>
                <div className="image-wrap" onClick={ (e) => this.toggleImagePopup(e, productItem) }>
                    <img src={ productItem.imageLocation ? productItem.imageLocation : './images/product.png' } alt={ productItem.name } />
                    { productItem.imageLocation &&
                        <span className="icon">
                            <span className="plus" />
                        </span>
                    }
                </div>
                <div className="product-content">
                    <h3> { productItem.name } </h3>
                    <div className="col-wrap">
                        <div className="col-item">
                            { descArray &&
                                descArray.map((descPart, descIndex) => (
                                    <p key={ descIndex }> { descPart } </p>
                                ))
                            }
                            <form className="input-tooltip-wrap" ref={ (el) => this.myCommentFormRef = el }>
                                <input type="text" placeholder={ locale.form.placeholder.comment }
                                    defaultValue={ currentItemOrderInfo.comment || null }
                                    disabled={ currentItemOrderInfo.quantity === 0 || !currentItemOrderInfo.priceIncVat }
                                    onKeyUp={ (e) => this.handleComment(e, productItem.id, productItem) }
                                />
                                { currentItemOrderInfo.quantity === 0 &&
                                    <span className="tooltiptext">{ locale.form.tooltip.comment }</span>
                                }
                            </form>
                        </div>
                        <div className="col">
                            <div className="product-price">
                                { this.state.isToggleCustomPrice || (currentItemOrderInfo.isChangeablePrice && currentItemOrderInfo.priceIncVat)
                                    ?
                                    <input className="custom-price" type="number" autoFocus
                                           // max={ +process.env.REACT_APP_MAX_CUSTOM_PRICE }
                                           defaultValue={ currentItemOrderInfo.priceIncVat > 0 ? currentItemOrderInfo.priceIncVat : '' }
                                           onBlur={ (e) => { this.getCustomPrice(e, productItem); } }
                                       />
                                    : <div className="price">{ currency(productItem.unitPriceIncVat).value.toFixed(2) }</div>
                                }
                            </div>
                            <div className="count-wrap">
                                <button className="decrement-count count-button" disabled={ currentItemOrderInfo.quantity === 0 } onClick={ () => { this.decrement(productItem.id); } } />
                                <div className="count">{ currentItemOrderInfo.quantity }</div>
                                <button className="increment-count count-button" onClick={ () => { this.increment(productItem); } } />
                            </div>
                        </div>
                    </div>
                </div>
                { !!this.state.errorCustomPrice &&
                    <span className="form-field-error">{ locale.form.validation.customPrice }</span>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isToggleCustomPrice: state.isToggleCustomPrice,
    errorCustomPrice: state.errorCustomPrice,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addQuantity,
    subtractQuantity,
    updateProduct,
    removeFromCart,
    addCommentAction,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);
