import { ApiInfoService } from 'company/service/index';
import ReactGA from 'react-ga';
import * as translation from '../../../locale/translation.json';
const currency = require('currency.js');

export default class CompanyService {

    public static getProductsArray(company) {
        const productsArray = [];
        if (!!company) {
            company.productGroups.map((group) => {
                group.products.map((product) => {
                    const filteredProd = {
                        id: product.id,
                        name: product.name,
                        desc: product.desc,
                        comment: null,
                        unitName: product.unitName,
                        priceIncVat: product.unitPriceIncVat,
                        priceExVat: product.unitPriceExVat,
                        vatPercent: product.vatPercent,
                        quantity: 0,
                        isChangeablePrice: product.unitPriceExVat === 0,
                    };
                    productsArray.push(filteredProd);
                });
            });
        }
        return productsArray;
    }

    public static getConcatProductsArrayAndOrder(company, order) {
        const productsArray = [];
        company.productGroups.map((group) => {
            group.products.map((product) => {
                const currentItem = order.find((item) => item.id === product.id);
                let priceIncVat = product.unitPriceIncVat;
                // if unitPrice = 0, check priceIncVat in order, if it's existed - show customPrice,
                // if not - calculate price from unitPrice and vatPercent for priceIncVat
                if (product.unitPriceExVat === 0 && currentItem) {
                    if (currentItem.priceIncVat > 0) {
                        priceIncVat = currentItem.priceIncVat;
                    } else {
                        currentItem.quantity = 0;
                        priceIncVat = 0;
                    }
                }

                const filteredProd = {
                    id: product.id,
                    name: product.name,
                    desc: product.desc,
                    comment: currentItem ? currentItem.comment : null,
                    unitName: product.unitName,
                    priceIncVat,
                    priceExVat: product.unitPriceExVat === 0 && currentItem && currentItem.priceIncVat > 0 ? currentItem.priceExVat : product.unitPriceExVat,
                    vatPercent: product.vatPercent,
                    quantity: currentItem ? currentItem.quantity : 0,
                    isChangeablePrice: product.unitPriceExVat === 0,
                };
                productsArray.push(filteredProd);
            });
        });
        return productsArray;
    }

    public static  calcPriceIncVat(price, vatPercent) {
        let priceIncVat = currency(price).multiply(1 + (vatPercent / 100)).value;

        // Perform the trick here - xx.99 must be rounded up and xx.01 down
        const priceArr = priceIncVat.toString().split('.');
        if (priceArr[ 1 ] === '01') {
            priceIncVat = currency(priceIncVat).subtract('0.01').value;
        } else if (priceArr[ 1 ] === '99') {
            priceIncVat = currency(priceIncVat).add('0.01').value;
        }
        return priceIncVat;
    }
    /*
    roundUp fn returns largest integer with few demical places that bigger than or equal to the value.
    'precision' - specifies the number of decimal places, for money - use 2
   */
    public static  roundUp(value) {
        const precision = 2;
        const multiplier = Math.pow(10, precision);
        return Math.ceil(value * multiplier) / multiplier;
    }

    /*
    round fn returns the value of a number rounded to the nearest integer.
    'precision' - specifies the number of decimal places, for money - use 2
   */
    public static  round(value) {
        const precision = 2;
        const multiplier = Math.pow(10, precision);
        return Math.round(value * multiplier) / multiplier;
    }

    public static setStorageCartData(order, priceIncVat, priceExVat) {
        const storedCartData = localStorage.getItem('orderDetails') ? JSON.parse(localStorage.getItem('orderDetails')) : {};
        const merchant = localStorage.getItem('merchantQuery');
        storedCartData[merchant] = {
            order,
            totalIncVat: priceIncVat,
            totalExVat: priceExVat,
        };
        localStorage.setItem('orderDetails', JSON.stringify(storedCartData));
    }

    /**
     * get data from localStorage
     * @param key - it's merchant params from url
     * @param storageName - it's name of item in localStorage
     */
    public static getStorageDataByItemName(key, storageName) {
        if (localStorage.getItem(storageName) &&
            JSON.parse(localStorage.getItem(storageName))[key]) {
            return JSON.parse(localStorage.getItem(storageName))[key];
        }
    }

    public static setStorageShopInfo(merchant, companyData) {
        const storedShopData = localStorage.getItem('shopInfo') ? JSON.parse(localStorage.getItem('shopInfo')) : {};
        storedShopData[merchant] = companyData;
        localStorage.setItem('shopInfo', JSON.stringify(storedShopData));
    }

    public static setStorageUserInfo(formData) {
        const merchant = localStorage.getItem('merchantQuery');
        const storedUserData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
        storedUserData[merchant] = formData;
        localStorage.setItem('userInfo', JSON.stringify(storedUserData));
    }

    public static callOrderRequest(formData, callBack, errorCallback) {
        ApiInfoService.postOrder(JSON.stringify(formData))
            .then((res) => {

                if (!res.orderNo || !res.transactionId) {
                    errorCallback(res, formData);
                    return res;
                }

                localStorage.setItem('orderNo', res.orderNo);
                localStorage.setItem('transactionId', res.transactionId);
                callBack();
                return res;
            })
            .then((res) => {

                if (localStorage.hasOwnProperty('transactionId') && res.paymentUrl) {
                    window.location.assign(res.paymentUrl);
                }
                ReactGA.event({
                    category: 'order',
                    action: 'post_request',
                });
            })
            .catch((err) => { errorCallback(err, formData); });
    }
}
