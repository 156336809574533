import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as history from 'history';
import classnames from 'classnames';
import ReactGA from 'react-ga';
// Components
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import CountryCodeSelect from 'components/countryCodeSelect/countryCodeSelect';
import OrderProduct from 'company/model/OrderProduct';
import ErrorPage from 'company/ui/ErrorPage';
// Model
import Company from 'company/model/Company';
import FormData from 'company/model/FormData';
import Error from 'company/model/Error';
// Service
import CompanyService from 'company/service/CompanyService';
// Constants
import { COUNTRY_CODE_SELECT_DEFAULT_VALUE } from 'company/constants';
import Spinner from 'components/spinner/Spinner';
import * as translation from '../../../locale/translation.json';
import Bugsnag from '@bugsnag/js';
import * as EmailValidator from 'email-validator';
import ErrorPopup from 'company/ui/ErrorPopup';
const currency = require('currency.js');
var LZUTF8 = require('lzutf8');

interface IOrderSummaryPageProps extends React.ClassAttributes<any> {
    companyData: Company;
    order: OrderProduct[];
    totalIncVat: number;
    totalExVat: number;
    location: history.location;
    history: history;
    btnText: string;
}

interface IOrderSummaryPageState extends React.ClassAttributes<any> {
    companyData: Company;
    orderDetails: {
        order: OrderProduct[],
        totalIncVat: number,
        totalExVat: number,
    };
    isOrderSubmit: boolean;
    isErrorPopupOpened: boolean;
    errorMessage: string;
    isPaymentInvalid: boolean;
    submitted: boolean;
    fields: FormData;
    errorValidation: {
        name: string;
        email: string;
        paymentMethod: string;
    };
    error: Error;
    formData: object;
    merchant: string;
}

class OrderSummaryPage extends React.Component<IOrderSummaryPageProps, IOrderSummaryPageState> {
    private formRef: React.RefObject<HTMLFormElement>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        this.submit = this.submit.bind(this);
        this.handlePaymentType = this.handlePaymentType.bind(this);
        this.calcVAT = this.calcVAT.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.errorCallback = this.errorCallback.bind(this);
        this.callBack = this.callBack.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        const merchant = localStorage.getItem('merchantQuery');
        const companyData = CompanyService.getStorageDataByItemName(merchant, 'shopInfo');
        let orderDetails;
        if (!this.props.location.search.includes('orderInfo=')) {
            orderDetails = CompanyService.getStorageDataByItemName(merchant, 'orderDetails');
        }
        if (this.props.location.search.includes('userInfo')) {
            console.log("Userinfo found in the url");
            const splitUrlParams = this.props.location.search.split('?');
            const paramsList = {
                merchant: '',
                userInfo: '',
                orderDetails: '',
            };
            splitUrlParams.map((el) => {
                const param = el.split('=');
                paramsList[param[0]] = param[1];
            });
            const trimmedUserInfoUrl = paramsList.userInfo.replace("&orderInfo", "");
            const userInfoDecodeUrl = decodeURIComponent(trimmedUserInfoUrl);
            const userInfoDecodedObj = this.atou(userInfoDecodeUrl);
            localStorage.setItem('userInfoNew', userInfoDecodedObj);
        } else {
            console.log("Userinfo NOT found in the url");
            console.log("NAVIGATOR LANGUAGE: " + navigator.language);
        }
        if (!!orderDetails) {
            localStorage.removeItem('currentOrder');
        }
        if (!orderDetails) {
            orderDetails = JSON.parse(localStorage.getItem('currentOrder'));
            console.log("Current order: " + orderDetails);
            localStorage.removeItem('currentOrder');
        }
        if (!orderDetails && this.props.location.search.includes('orderInfo=')) {
            console.log("Order details not found in local storage!");
            let orderDetailsParam = {
                orderDetails: '',
            };
            const orderInfoUrlArr = this.props.location.search.split('orderInfo=');
            console.log("THE URL: " + this.props.location.search);
            orderDetailsParam.orderDetails = orderInfoUrlArr[1];
            if (!!orderDetailsParam.orderDetails) {
                const orderInfoUrlDecoded = decodeURIComponent(orderDetailsParam.orderDetails);
                console.log("OrderDetails: " + orderDetailsParam.orderDetails);
                let orderDetailsData = LZUTF8.decodeBase64(orderInfoUrlDecoded);
                console.log("Base64 decoded order details: " + orderDetailsData);
                orderDetailsData = LZUTF8.decompress(orderDetailsData);
                console.log("Decompressed orderDetailsData: " + orderDetailsData);
                try {
                   orderDetailsData = orderDetailsData.substring(orderDetailsData.indexOf('{"order"'), orderDetailsData.length);
                    console.log("OrderDetails DATA:" + orderDetailsData);
                    orderDetails = JSON.parse(orderDetailsData);
                    localStorage.setItem('currentOrder', orderDetailsData);
                } catch (error) {
                    console.log("ERROR: " + error);
                }
            }
                            
        }
        if (!orderDetails) {
            console.log("Still no order details!");
        }
        
        this.state = {
            companyData,
            orderDetails,
            isOrderSubmit: false,
            isErrorPopupOpened: false,
            errorMessage: '',
            isPaymentInvalid: false,
            submitted: false,
            fields: {
                addressCountryCode: COUNTRY_CODE_SELECT_DEFAULT_VALUE,
                paymentMethod: '',
                email: '',
                name: '',
                firstName: '',
                lastName: '',
                birthDate: '',
                mobileNumber: '',
                comment: '',
                address: '',
                postcode: '',
                city: '',
            },
            errorValidation: {
                name: null,
                email: null,
                paymentMethod: null,
            },
            error: {
                status: undefined,
                errorCode: undefined,
                message: undefined,
                userMessage: undefined,
                developerMessage: undefined,
                moreInfo: undefined,
                isError: false,
            },
            formData: undefined,
            merchant,
        };
    }

    private calcVAT(totalIncVat, totalExVat) {
        return currency(totalIncVat).subtract(
            currency(totalExVat).value,
        ).value;
    }

    private handlePaymentType(e) {
        if (e.target.checked) {
            const fields = this.state.fields;
            fields.paymentMethod = e.target.value;

            this.setState({
                fields,
                isPaymentInvalid: false,
            });
            CompanyService.setStorageUserInfo(fields);

        }
    }

    private handleChange(field, e) {
        const fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
        CompanyService.setStorageUserInfo(fields);
    }

    private submit(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            const filteredOrder = this.state.orderDetails.order.map((product) => {
                return {
                    productId: product.id,
                    quantity: product.quantity,
                    unitPriceExVat: product.priceExVat,
                    unitPriceIncVat: product.priceIncVat,
                    comment: product.comment,
                };
            });
            const fields = new Object(this.state.fields);

            for (const key in fields) {
                if (!fields[key]) {
                    delete fields[key];
                }
            }

            const formData = {
                ...fields,
                companyIdent: this.state.companyData.companyIdent,
                companyCountryCode: this.state.companyData.countryCode,
                deptIdent: this.state.companyData.deptIdent,
                orderLines: filteredOrder,
                totalIncVat: this.state.orderDetails.totalIncVat,
                totalExVat: this.state.orderDetails.totalExVat,
            };
            if (!formData.deptIdent) {
                delete formData.deptIdent;
            } else if (formData.deptIdent.includes("?")) {
                const deptArr = formData.deptIdent.split("?");
                formData.deptIdent = deptArr[0];
                console.log("The trimmed department is: " + formData.deptIdent); 
            }
            this.setState({ formData: { formData } });

            if (!this.state.isOrderSubmit) {
                CompanyService.callOrderRequest(formData, this.callBack, this.errorCallback);
                this.setState({ isOrderSubmit: true });
            }
        } else {
            const headerHeight = 66;
            const top = this.formRef.current.offsetTop - headerHeight; // less on height of header block
            window.scrollTo(0, top);
        }
    }

    private callBack() {
        this.setState({ isOrderSubmit: false });
    }

    private errorCallback(err, formData) {
        if (err.message && (!err.orderNo || !err.transactionId)) {
            this.setState({
                isOrderSubmit: false,
                isErrorPopupOpened: true,
                errorMessage: err.message,
            });
        } else {
            this.props.history.push({
                pathname: '/error',
                state: { error: err },
            });
        }

        ReactGA.exception({
            description: 'An_error_occurred_in_post_order_request',
            fatal: true,
        });

        const id = localStorage.getItem('bugsnag-anonymous-id');
        const countryCode = formData.companyCountryCode;
        const companyIdent = formData.companyIdent;
        const deptIdent = formData.deptIdent;
        Bugsnag.setUser(id, formData.email, formData.name);
        Bugsnag.addMetadata('merchant', {
            name: this.state.companyData.displayName ? this.state.companyData.displayName : this.state.companyData.name,
            country: countryCode,
            companyIdent,
            deptIdent,
        });
        Bugsnag.addMetadata('response', {
            data: JSON.stringify(formData),
            json: JSON.stringify(err),
        });

        Bugsnag.notify(`Posting order failed, errorCode: ${ err.status }, message: ${ err.message }`);
    }

    private handleValidation() {
        const fields = this.state.fields;
        const errorValidation = {
            paymentMethod: undefined,
            name: undefined,
            email: undefined,
        };
        let formIsValid = true;
        let locale = translation.nb.translation;
        if (navigator.language.substr(0, 2).includes("en")) {
            locale = translation['en-GB'].translation;
        } else if (navigator.language.substr(0, 2).includes("fi")) {
            locale = translation['fi'].translation;
        } else if (navigator.language.substr(0, 2).includes("sv")) {
            locale = translation['sv'].translation;
        } else if (navigator.language.substr(0, 2).includes("da")) {
            locale = translation['da'].translation;
        }

        // paymentMethod
        if (!fields || !fields.paymentMethod) {
            formIsValid = false;
            this.setState({
                isPaymentInvalid: true,
            });
            errorValidation.paymentMethod = locale.form.validation.required.payment;
        }

        // Name
        if (!fields || !fields.name) {
            formIsValid = false;
            errorValidation.name = locale.form.validation.required.message;
        } else {
            if (!fields.name.match(/^[a-zA-Z\u00C0-\u024F ,.'-]+$/i)) {
                formIsValid = false;
                errorValidation.name = locale.form.validation.invalid.name;
            }

            if (fields.name.length < 2) {
                formIsValid = false;
                errorValidation.name = locale.form.validation.nameLength;
            }
        }

        // Email
        if (!fields || !fields.email) {
            formIsValid = false;
            errorValidation.email = locale.form.validation.required.message;
        } else {
            formIsValid = EmailValidator.validate(fields.email);
            if (!formIsValid) {
                errorValidation.email = locale.form.validation.invalid.email;
            }
        }

        this.setState({ errorValidation });
        return formIsValid;
    }

    /**
     * ASCII to Unicode (decode Base64 to original data)
     * @param {string} b64
     * @return {string}
     */
    private atou(b64) {
        return decodeURIComponent(escape(atob(b64)));
    }

    public componentDidMount() {
        window.scrollTo(0, 0);
        let merchantString = "";
        let hideVipps = false;
        try {
            const merchant = localStorage.getItem('merchantQuery');
            hideVipps = merchant.includes("countryCode=NO") ? false : true;
        } catch (err) {
            console.log("Error setting hideVipps: " + err);
        }

        let defaultPayment = 'PAYMENT_CARD';
        if (!!this.state.companyData && !!this.state.companyData.paymentMethods && this.state.companyData.paymentMethods.includes('VIPPS') && !hideVipps) {
            defaultPayment = 'VIPPS';
        }
        // Important: 'const' only ensures that 'fields' always references the same object,
        // but we redefine paymentMethod in for get default state
        let fields = this.state.fields;
        fields.paymentMethod = defaultPayment;
        let orderDetails = this.state.orderDetails;

        if (this.props.location.search) {
            const userInfoObj = {
                country: '',
                email: '',
                firstName: '',
                lastName: '',
                mobileNumber: '',
                name: '',
                birthDate: '',
                postalCode: '',
                region: '',
                streetAddress: '',
            };

            let userInfoEncodeParamsArr = this.props.location.search.split('?');
            if (!this.props.location.search.includes('orderInfo')) {
                console.log("Trying to split url from localstorage");
                const storedUrl = localStorage.getItem('loggedInLocation');
                if (!!storedUrl) {
                    userInfoEncodeParamsArr = storedUrl.split('?');
                }
                console.log("Split stored url successfully");
                
                
            } 
            console.log("Url split successfully");
            const paramsList = {
                merchant: '',
                userInfo: '',
            };
            userInfoEncodeParamsArr.map((el) => {
                const param = el.split('=');
                paramsList[param[0]] = param[1];
            });

            console.log("Userinfo:" + paramsList.userInfo);
            
            this.setState({
                merchant: atob((paramsList.merchant)),
            });
            merchantString = atob(paramsList.merchant);

            // const userInfoEncodeParams = this.props.location.search.replace('?', '').split('=')[1];
            if (!!paramsList.userInfo) {
                const trimmedUserInfoUrl = paramsList.userInfo.replace("&orderInfo", "");
                const userInfoDecodeUrl = decodeURIComponent(trimmedUserInfoUrl);
                const userInfoDecodedObj = JSON.parse(this.atou(userInfoDecodeUrl));

                // tslint:disable-next-line:forin
                for (const key in userInfoDecodedObj) {
                    userInfoObj[key] = userInfoDecodedObj[key];
                }
                let fullName = '';
                if (!!userInfoObj.firstName && !!userInfoObj.lastName) {
                    fullName = userInfoObj.firstName + ' ' + userInfoObj.lastName;
                }
                fields.email = userInfoObj.email;
                fields.name = userInfoObj.name !== '' ? userInfoObj.name : fullName;
                fields.firstName = userInfoObj.firstName;
                fields.lastName = userInfoObj.lastName;
                fields.birthDate = userInfoObj.birthDate;
                fields.mobileNumber = userInfoObj.mobileNumber;
                fields.postcode = userInfoObj.postalCode;
                fields.city = userInfoObj.region;
                fields.address = userInfoObj.streetAddress;
                fields.addressCountryCode = userInfoObj.country;
            } else {
                console.log("Trying to get userinfo from storage!!");
                const savedUserInfo = localStorage.getItem('userInfoNew');
                const userInfoDecodedObj = JSON.parse(savedUserInfo);
                // tslint:disable-next-line:forin
                for (const key in userInfoDecodedObj) {
                    userInfoObj[key] = userInfoDecodedObj[key];
                }
                let fullName = '';
                if (!!userInfoObj.firstName && !!userInfoObj.lastName) {
                    fullName = userInfoObj.firstName + ' ' + userInfoObj.lastName;
                }
                fields.email = userInfoObj.email;
                fields.name = userInfoObj.name !== '' ? userInfoObj.name : fullName;
                fields.firstName = userInfoObj.firstName;
                fields.lastName = userInfoObj.lastName;
                fields.birthDate = userInfoObj.birthDate;
                fields.mobileNumber = userInfoObj.mobileNumber;
                fields.postcode = userInfoObj.postalCode;
                fields.city = userInfoObj.region;
                fields.address = userInfoObj.streetAddress;
                fields.addressCountryCode = userInfoObj.country;
            }

        } else {
            const userInfo = CompanyService.getStorageDataByItemName(this.state.merchant, 'userInfo');
            if (!!userInfo) {
                fields = userInfo;
            }
        }
        this.setState({
            fields,
        });
        if (!this.state.companyData) {
            const fullUrl = window.location.href;
            const urlPart = fullUrl.substring(fullUrl.indexOf('/#/?'));
            localStorage.setItem('loggedInLocation', urlPart);
            window.location.assign('/#/?' + merchantString + "&loggedIn=true");
            
        }
        if (!this.state.orderDetails && !!this.state.merchant) {
            console.log("Something is missing");
            window.location.assign('/#/' + this.state.merchant);
            Bugsnag.notify(`Redirect from order page to product page (in mount 1st case), because companyData=${ this.state.companyData }, orderDetails = ${ this.state.orderDetails }, merchant=${ this.state.merchant }`);
        } else if (!this.state.orderDetails && !this.state.merchant) {
            const userInfoEncodeParamsArr = this.props.location.search.split('?');
            const paramsList = {
                merchant: '',
                userInfo: '',
            };
            userInfoEncodeParamsArr.map((el) => {
                const param = el.split('=');
                paramsList[param[0]] = param[1];
            });
            if (!!paramsList.merchant) {
                const merchant = atob(paramsList.merchant);
                window.location.assign('/#/?' + merchant);
                Bugsnag.notify(`Redirect form order page to products page (in mount 2nd case), because companyData=${ this.state.companyData }, orderDetails = ${ this.state.orderDetails }, merchant=${ merchant }`);
            } else {
                window.location.assign('/#/info');
                Bugsnag.notify(`Redirect form order page to info page (in mount 3rd case), because companyData=${ this.state.companyData }, orderDetails = ${ this.state.orderDetails }, merchant=${ this.state.merchant }`);
            }
        }
    }

    private toggleModal() {
        this.setState({
            isErrorPopupOpened:  !this.state.isErrorPopupOpened,
        });

    }

    public render() {
        console.log(this.state.orderDetails);
        const { location } = this.props;
        if (!!this.state.orderDetails && this.state.orderDetails.order.length === 0) {
            window.location.assign('/#/?' + this.state.merchant);
        }

        
        let hideVipps = false;
        let locale = translation.nb.translation;
        try {
            if (navigator.language.substr(0, 2).includes("en")) {
                locale = translation['en-GB'].translation;
            } else if (navigator.language.substr(0, 2).includes("fi")) {
                locale = translation['fi'].translation;
            } else if (navigator.language.substr(0, 2).includes("sv")) {
                locale = translation['sv'].translation;
            } else if (navigator.language.substr(0, 2).includes("da")) {
                locale = translation['da'].translation;
            }
            const merchant = localStorage.getItem('merchantQuery');
            hideVipps = merchant.includes("countryCode=NO") ? false : true;
        } catch (err) {
            console.log("Error setting translation: " + err);
        }
        
        return (
            <div>
                { !!this.state.companyData && !!this.state.orderDetails &&
                    <div id="wrapper" className={ classnames({ 'default-overlay': this.state.isOrderSubmit, 'checkout': location && location.pathname.indexOf('/order') > -1 }) }>
                        <Header isFrontPage={ false } companyData={ this.state.companyData }/>
                        <main id="main">
                            <div className="container">
                                <div className="order-block">
                                    <h2>{ locale.title.order }</h2>
                                    <div className="order-list">
                                        { this.state.orderDetails && this.state.orderDetails.order.map((orderItem, orderItemIndex) => (
                                            <div className="order-item" key={ orderItemIndex }>
                                                <h3>{ orderItem.name }</h3>
                                                <span className="comment">{ orderItem.comment }</span>
                                                <div className="count-price-row">
                                                    <div className="col">{ orderItem.quantity + ' ' + orderItem.unitName } a { this.state.companyData.currencySymbol }</div>
                                                    <div className="col">{ currency(orderItem.priceIncVat).value.toFixed(2) }</div>
                                                </div>
                                                <div className="row-total">
                                                    <div className="col">{ locale.amount.sum } { this.state.companyData.currencySymbol }</div>
                                                    <div className="col">{ currency(orderItem.priceIncVat).multiply(orderItem.quantity).value.toFixed(2) }</div>
                                                </div>
                                            </div>
                                        )) }
                                    </div>
                                    <div className="total-amount">
                                        <div className="row">
                                            <div className="col">{ locale.amount.total } { this.state.companyData.currencySymbol }</div>
                                            <div className="col">{ currency(this.state.orderDetails.totalIncVat).value.toFixed(2) }</div>
                                        </div>
                                        <div className="row">
                                            <div className="col">{ locale.amount.incVat } { this.state.companyData.currencySymbol }</div>
                                            <div className="col">{ this.calcVAT(this.state.orderDetails.totalIncVat, this.state.orderDetails.totalExVat).toFixed(2) }</div>
                                        </div>
                                    </div>
                                </div>
                                <form id="order" ref={ this.formRef } className={ classnames({ submitted: this.state.submitted }) } noValidate onSubmit={ (e) => this.submit(e) }>
                                    <div className="payment-block">
                                        <h2>{ locale.title.payment }</h2>
                                        <div className={ classnames('payment-list', { invalid: !!this.state.isPaymentInvalid }) }>
                                            <div className={ classnames('payment-item', { 'payment-hidden': this.state.companyData.paymentMethods && !this.state.companyData.paymentMethods.includes('VIPPS') }) }>
                                                {(hideVipps) ? <span></span> : <input name="payment-type" type="radio" id="vipps" value="VIPPS"
                                                       disabled={ this.state.companyData.paymentMethods && !this.state.companyData.paymentMethods.includes('VIPPS') }
                                                       checked={ (this.state.fields && this.state.fields.paymentMethod === 'VIPPS' && !hideVipps) ?? false }
                                                       onChange={ (e) => this.handlePaymentType(e) }
                                                       hidden={hideVipps}/> }
                                                <label htmlFor="vipps" className="card-type" >
                                                    <img src="./images/Vipps.png" alt={ locale.payment.vipps } hidden={hideVipps}/>
                                                    {(hideVipps) ? <span></span> : <span>{locale.payment.vipps }</span>}
                                                </label>
                                            </div>
                                            <div className={ classnames('payment-item', { 'payment-hidden':  this.state.companyData.paymentMethods && !this.state.companyData.paymentMethods.includes('PAYMENT_CARD') }) }>
                                                <input name="payment-type" type="radio" id="card" value="PAYMENT_CARD"
                                                       disabled={  this.state.companyData.paymentMethods && !this.state.companyData.paymentMethods.includes('PAYMENT_CARD') }
                                                       checked={ (this.state.fields && this.state.fields.paymentMethod === 'PAYMENT_CARD') ?? false }
                                                       onChange={ (e) => this.handlePaymentType(e) }/>
                                                <label htmlFor="card" className="card-type">
                                                    <img src="./images/Card.png" alt={ locale.payment.card }/>
                                                    <span>{ locale.payment.card }</span>
                                                </label>
                                            </div>
                                            { !!this.state.errorValidation.paymentMethod &&
                                                <span className="form-field-error">{ this.state.errorValidation.paymentMethod }</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="information-block">
                                        <h2>{ locale.title.information }</h2>
                                        <div className="input-row">
                                            <label htmlFor="email">{ locale.form.label.email }<sup>*</sup></label>
                                            <input type="email" id="email" required
                                                   placeholder={ locale.form.placeholder.email }
                                                   defaultValue={ this.state.fields && this.state.fields.email ? this.state.fields.email : '' }
                                                   onChange={ (e) => this.handleChange('email', e) }
                                            />
                                            { !!this.state.errorValidation.email &&
                                            <span className="form-field-error">{ this.state.errorValidation.email }</span>
                                            }
                                        </div>
                                        <div className="input-row">
                                            <label htmlFor="name">{ locale.form.label.name }<sup>*</sup></label>
                                            <input type="text" id="name" required
                                                   placeholder={ locale.form.placeholder.name }
                                                   defaultValue={ this.state.fields && this.state.fields.name ? this.state.fields.name : '' }
                                                   onChange={ (e) => this.handleChange('name', e) }
                                            />
                                            { !!this.state.errorValidation.name &&
                                            <span className="form-field-error">{ this.state.errorValidation.name }</span>
                                            }
                                        </div>
                                        <div className="input-row">
                                            <label htmlFor="reference">{ locale.form.label.comment }</label>
                                            <input type="text" id="reference"
                                                   placeholder={ locale.form.placeholder.reference }
                                                   defaultValue={ this.state.fields && this.state.fields.comment ? this.state.fields.comment : '' }
                                                   onChange={ (e) => this.handleChange('comment', e) }
                                            />
                                        </div>
                                        <div className="input-row">
                                            <label htmlFor="address">{ locale.form.label.address }</label>
                                            <input type="text" id="address"
                                                   placeholder={ locale.form.placeholder.address }
                                                   defaultValue={ this.state.fields && this.state.fields.address ? this.state.fields.address : '' }
                                                   onChange={ (e) => this.handleChange('address', e) }
                                            />
                                        </div>
                                        <div className="input-row">
                                            <label htmlFor="postcode">{ locale.form.label.postcode }</label>
                                            <input type="number" id="postcode"
                                                   placeholder={ locale.form.placeholder.postcode }
                                                   defaultValue={ this.state.fields && this.state.fields.postcode ? this.state.fields.postcode : '' }
                                                   onChange={ (e) => this.handleChange('postcode', e) }
                                            />
                                        </div>
                                        <div className="input-row">
                                            <label htmlFor="city">{ locale.form.label.city }</label>
                                            <input type="text" id="city"
                                                   placeholder={ locale.form.placeholder.city }
                                                   defaultValue={ this.state.fields && this.state.fields.city ? this.state.fields.city : '' }
                                                   onChange={ (e) => this.handleChange('city', e) }
                                            />
                                        </div>
                                        <CountryCodeSelect handleChange={ this.handleChange } countryValue={ this.state.fields.addressCountryCode }/>
                                    </div>
                                </form>
                            </div>
                        </main>
                        <Footer history={ history } location={ location } btnText={ locale.button.pay } totalIncVat={ this.state.orderDetails.totalIncVat } companyData={ this.state.companyData } loaderActivation={ null }  />

                        { this.state.isOrderSubmit &&
                            <div className="spinner-overlay">
                                <Spinner class="order-is-submit" />
                            </div>
                        }

                        { !!this.state.isErrorPopupOpened &&
                            <ErrorPopup toggleModal={ this.toggleModal } errorMessage={ this.state.errorMessage } />
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    companyData: state.companyData,
    order: state.cart.addedItems,
    totalIncVat: state.cart.totalIncVat,
    totalExVat: state.cart.totalExVat,
    orderDetails: state.orderDetails,
    isOrderSubmit: state.isOrderSubmit,
    isPaymentInvalid: state.isPaymentInvalid,
    errorValidation: state.errorValidation,
    fields: state.fields,
    error: state.error,
    errorMessage: state.errorMessage,
});

export default connect(mapStateToProps)(OrderSummaryPage);
