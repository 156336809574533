import MainPage from 'company/ui/MainPage';
import CompanyBrowsePage from 'company/ui/BrowsePage';
import OrderSummaryPage from 'company/ui/OrderSummaryPage';
import PaymentResultPage from 'company/ui/PaymentResultPage';
import ErrorPage from 'company/ui/ErrorPage';

const publicRoutes = [
  {
    exact: true,
    component: MainPage,
    path: '/info',
  },
  {
    exact: true,
    component: CompanyBrowsePage,
    path: '/',

  },
  {
    exact: true,
    component: OrderSummaryPage,
    path: '/order',
  },
  {
    exact: true,
    component: PaymentResultPage,
    path: '/payment-result',
  },
  {
    component: ErrorPage,
    path: '/error',
  },
];

export { publicRoutes as productsPublicRotes };
