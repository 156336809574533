import * as React from 'react';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import {bindActionCreators} from 'redux';
import * as history from 'history';

import Company from 'company/model/Company';
import OrderProduct from 'company/model/OrderProduct';
import OrderDetails from 'company/model/OrderDetails';
import CompanyService from 'company/service/CompanyService';
import ProductItem from 'components/productItem/ProductItem';

interface IProductsPageProps extends React.ClassAttributes<any> {
    companyData: Company;
    order: OrderProduct[];
    orderState: OrderProduct[];
    location: history.location;
    togglePopup: (state, initialHeight) => void;
    filter: String;
}

interface IProductsPageState extends React.ClassAttributes<any> {
    order: OrderProduct[];
    filteredCompanyData: Company;
}

class ProductsPage extends React.Component<IProductsPageProps, IProductsPageState> {
    constructor(props) {
        super(props);
        this.state = {
            order: [],
            filteredCompanyData: null,
        };
        this.currentItemOrderInfo = this.currentItemOrderInfo.bind(this);
    }

    // currentItemOrderInfo fn return quantity, comment and flag isChangeablePrice,
    // where isChangeablePrice = true, when productItem.unitPrice === 0 to create custom price
    private currentItemOrderInfo(id, order) {
        const currentItem = order.find((item) => item.id === id);
        const quantity = !!currentItem ? currentItem.quantity : 0;
        const comment = !!currentItem ? currentItem.comment : null;
        const isChangeablePrice = !!currentItem ? currentItem.isChangeablePrice : false;
        const priceIncVat = !!currentItem ? currentItem.priceIncVat : 0;
        return { quantity, comment, isChangeablePrice, priceIncVat };
    }

    public render() {
        const { companyData, order, togglePopup, filter } = this.props;
        return (
            <div>
                { companyData &&
                <main id="main">
                    <div className="container">
                        { 
                          /* companyData.productGroups.map((productGroupsItem, productGroupsIndex) => (
                            <Element className="product-group" name={ 'cathegory-' + productGroupsIndex } key={ productGroupsIndex }>
                                <h2>{ productGroupsItem.name }</h2>
                                <div className="products-wrap">
                                    { productGroupsItem.products.map((productItem, productIndex) => (
                                        <ProductItem togglePopup={ togglePopup } productItem={ productItem } key={ productIndex } currentItemOrderInfo={ this.currentItemOrderInfo(productItem.id, order) } />
                                    )) }
                                </div>
                            </Element>
                        ))  } */
                        companyData.productGroups.filter((val)=>{
                          if(filter === ""){
                            return val
                          } else {
                  
                            let containsProducts = false;
                            const parts = filter.split(/[ ,-]+/);
                            let found = true;
                            
                            for (const product of val.products) {
                              if(parts.length >= 1){
                                for(const part of parts){
                                  if(product.name.toLowerCase().includes(part.toLowerCase()) && (part !== "" && part !== " ")){
                                    containsProducts = true;
                                    break;
                                  }
                                }
                              }
                            }
                            
                  
                            if(containsProducts){
                              return val
                            }         
                          }
                        }).map((productGroupsItem, productGroupsIndex) => (
                            <Element className="product-group" name={ 'cathegory-' + productGroupsIndex } key={ productGroupsIndex }>
                                <h2>{ productGroupsItem.name }</h2>
                                <div className="products-wrap">
                                    { 
                                    productGroupsItem.products.filter((val)=>{
                                        if(filter === ""){
                                          return val
                                        } else {
                                
                                          const parts = filter.split(/[ ,-]+/);
                                          
                                          let found = true;
                                
                                          if(parts.length >= 1){
                                            for(const part of parts){
                                              if(!val.name.toLowerCase().includes(part.toLowerCase())){
                                                found = false;
                                              }
                                            }
                                          }
                                
                                          if(found){
                                            return val
                                          }         
                                        }
                                      }).map((val, key)=>{
                                          console.log(val.name);
                                        return val
                                      }).map((productItem, productIndex) => (
                                        <ProductItem togglePopup={ togglePopup } productItem={ productItem } key={ productIndex } currentItemOrderInfo={ this.currentItemOrderInfo(productItem.id, order) } />
                                    )) }
                                </div>
                            </Element>
                        ))  }
                    </div>
                </main>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    order: state.cart.addedItems,
    companyData: state.company.companyData,
});

export default connect(mapStateToProps)(ProductsPage);
