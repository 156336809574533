import * as React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Bugsnag from '@bugsnag/js';
import * as history from 'history';
// Components
import SuccessPayment from 'components/defaultPage/SuccessPayment';
import FailedPayment from 'components/defaultPage/FailedPayment';
import DefaultPage from 'components/defaultPage/DefaultPage';
// Services
import { ApiInfoService } from 'company/service';
import Error from 'company/model/Error';
import ErrorPage from 'company/ui/ErrorPage';

interface IPaymentProps extends React.ClassAttributes<any> {
    history: history;
}
interface IPaymentState extends React.ClassAttributes<any> {
    isSuccessfulPayment: boolean;
    companyName: string;
    orderNo: string;
    email: string;
    paymentStatus: string;
    error: Error;
    merchant: string;
}

class PaymentResult extends React.Component<IPaymentProps, IPaymentState> {
    constructor(props) {
        super(props);
        this.state = {
            isSuccessfulPayment: null,
            merchant: null,
            companyName: '',
            orderNo: '',
            email: '',
            paymentStatus: '',
            error: {
                status: undefined,
                errorCode: undefined,
                message: undefined,
                userMessage: undefined,
                developerMessage: undefined,
                moreInfo: undefined,
                isError: false,
            },
        };
    }

    public componentDidMount(): void {
        new Promise((resolve) => {
            const searchParam = window.location.hash.split('?')[1];
            const params = searchParam.split('&');
            const transIdParam = params.find((item) => item.includes('transId'));
            const transId = transIdParam.split('=')[1];
            const merchant = localStorage.getItem('merchantQuery');
            this.setState({
                merchant,
            });
            resolve(transId);
        })
            .then((transId) => {
                ApiInfoService.getPaymentStatus(transId)
                    .then((res) => {
                        this.setState({
                            paymentStatus: res.paymentStatus,
                            companyName: res.companyName,
                            orderNo: res.orderNo,
                            email: res.customerEmail,
                        });
                        if (res.paymentStatus === 'PAID') {
                            this.setState({ isSuccessfulPayment: true });
                        } else {
                            this.setState({ isSuccessfulPayment: false });

                            const id = localStorage.getItem('bugsnag-anonymous-id');
                            Bugsnag.setUser(id, res.customerEmail, res.customerName);
                            Bugsnag.addMetadata('merchant', {
                                name: res.companyName,
                            });
                            Bugsnag.addMetadata('response', {
                                json: JSON.stringify(res),
                            });
                            Bugsnag.notify(`Payment failed- status: ${ res.paymentStatus }`);
                        }
                        ReactGA.event({
                            category: 'payment',
                            action: 'get_payment_status',
                        });
                    })
                    .catch((err) => {
                        const error = err;
                        error.isError = true;
                        this.setState({ error });
                        ReactGA.exception({
                            description: 'An_error_occurred_in_get_payment_status',
                            fatal: true,
                        });
                        Bugsnag.addMetadata('response', {
                            json: JSON.stringify(err),
                        });
                        Bugsnag.notify(`Payment failed, errorCode: ${ err.status }, message: ${ err.message }`);
                    });
            });
    }

    public render() {
        const { history } = this.props;
        return (
            <div>
                { !this.state.error.isError
                    ? <div className="default-page-wrap payment-result">
                        { this.state.isSuccessfulPayment !== null
                            ?
                            !!this.state.isSuccessfulPayment
                                ? <SuccessPayment paymentData={ this.state }/>
                                : <FailedPayment/>
                            : <DefaultPage/>
                        }
                    </div>
                    : <div className="spinner-overlay">
                        <ErrorPage history={ history } requestType="transaction" formData={ null } error={ this.state.error }/>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isSuccessfulPayment: state.isSuccessfulPayment,
    companyName: state.companyName,
    orderNo: state.orderNo,
    email: state.customerEmail,
});

export default connect(mapStateToProps)(PaymentResult);
