import * as React from 'react';
import * as translation from '../../../locale/translation.json';


interface IConfirmationProps extends React.ClassAttributes<any> {
    goToOrder: () => void;
    goToLogin: () => void;
}

interface IConfirmationState extends React.ClassAttributes<any> {
}

class ConfirmationPopup extends React.Component<IConfirmationProps, IConfirmationState> {
    constructor(props) {
        super(props);

    }

    public render() {
        const { goToOrder, goToLogin } = this.props;
        const locale = translation.nb.translation;

        return (
            <div className="popup-overlay popup-window confirmation-popup">
                <div className="popup">
                    <div className="text-block">
                        <p>{ locale.message.vippsLogin }<br/>{locale.message.vippsLoginPart2}</p>
                        <div className="btn-group">
                            <button className="cancel-btn" onClick={ () => goToOrder() }>{ locale.button.cancel }</button>
                            <button onClick={ () => goToLogin() }>{ locale.button.continue }</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmationPopup;
