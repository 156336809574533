import axios, { AxiosInstance } from 'axios';
// Model
import Company from 'company/model/Company';
import OrderData from 'company/model/OrderData';
import Transaction from 'company/model/Transaction';

// test url for ajax
const baseUrl = process.env.REACT_APP_BASE_URL;
const stage = '/' + process.env.REACT_APP_STAGE;
const GET_PRODUCTS_URL = baseUrl + stage + '/merchants';
// const GET_PRODUCTS_URL = 'http://localhost:9999/merchants';
const POST_ORDER_URL = baseUrl + stage + '/orders';
const GET_PAYMENT_STATUS_URL = baseUrl + stage + '/transactions';
const GET_LOGIN_DATA_URL = baseUrl + stage + '/login';
const content = { /* No content in request body */ };


class ApiInfoService {
    private _axios: AxiosInstance;
    public constructor(axios: AxiosInstance) {
        this._axios = axios;
    }

    public getCompany(query: any): Promise<Company> {

        return this._axios.request({
            // url: GET_PRODUCTS_URL,
            url: GET_PRODUCTS_URL + query,
            method: 'GET',
            ...content,
        }).then(({ data }) => data as Company);

    }

    public postOrder(formData): Promise<OrderData> {

        return this._axios.request({
            url: POST_ORDER_URL,
            method: 'POST',
            data: formData,
        }).then(({ data }) => data as OrderData);
    }

    public getPaymentStatus(transId): Promise<Transaction> {
        return this._axios.request({
            url: GET_PAYMENT_STATUS_URL + '/' + transId,
            method: 'GET',
            ...content,
        }).then(({ data }) => data as Transaction);
    }

}

export default ApiInfoService;

