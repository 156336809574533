import * as React from 'react';
import { Link } from 'react-router-dom';
import * as translation from '../../../locale/translation.json';

class FailedPayment extends React.Component<any> {
    constructor(props) {
        super(props);
    }

    public render() {
        const locale = translation.nb.translation;
        return (
            <div className="text-block">
                <div className="cross-icon" />
                <h1>{ locale.title.failed }</h1>
                <p>{ locale.message.failed.smthWrong }<br/>
                    { locale.message.failed.paymentFailedMessage }
                </p>
                <Link to="/order" className="action btn">{ locale.button.back }</Link>
            </div>
        );
    }
}
export default FailedPayment;
