import * as React from 'react';
import * as translation from '../../../locale/translation.json';
import { Link } from 'react-router-dom';

class SuccessPayment extends React.Component<any> {
    constructor(props) {
        super(props);
    }

    public componentDidMount(): void {
        const { paymentData } = this.props;
        const merchantQuery = paymentData.merchant;
        localStorage.clear();
        localStorage.setItem('merchantQuery', merchantQuery);
    }

    public render() {
        const { paymentData } = this.props;
        let locale = translation.nb.translation;
        if (navigator.language.substr(0, 2).includes("en")) {
        locale = translation['en-GB'].translation;
        } else if (navigator.language.substr(0, 2).includes("fi")) {
            locale = translation['fi'].translation;
        } else if (navigator.language.substr(0, 2).includes("sv")) {
            locale = translation['sv'].translation;
        } else if (navigator.language.substr(0, 2).includes("da")) {
            locale = translation['da'].translation;
        }
        const thanksMessageArr = locale.message.thanks;
        const orderNo = '%{ orderNo }';
        const email = '%{ email }';
        const urlGoBack = paymentData.merchant ? `/${ paymentData.merchant }` : '/info';
        const newThanksMessageArr = thanksMessageArr.map((thanksMessageItem) => {
            let thanksMessageItemNew = thanksMessageItem;
            if (thanksMessageItem.includes(orderNo)) {
                thanksMessageItemNew = thanksMessageItemNew.replace(orderNo, paymentData.orderNo);
            }
            if (thanksMessageItem.includes(email)) {
                thanksMessageItemNew = thanksMessageItemNew.replace(email, paymentData.email);
            }
            return thanksMessageItemNew;
        });
        return (
            <div className="text-block">
                <div className="check-icon" />
                <h1>{ locale.title.thanks } { paymentData.companyName }</h1>
                <p>
                    { newThanksMessageArr && newThanksMessageArr.map((messageItem, messageItemIndex) => (
                        <span key={ messageItemIndex }>
                            { messageItem }&#32;
                        </span>
                    )) }
                </p>
                <Link to={ urlGoBack } className="action btn">OK</Link>
            </div>
        );
    }
}
export default SuccessPayment;
