import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as translation from '../../../locale/translation.json';
import * as history from 'history';

interface IMainPageProps extends React.ClassAttributes<any> {
  history: history;
}

interface IMainPageState extends React.ClassAttributes<any> {
  hideBanner: boolean;
}

class MainPage extends React.Component<IMainPageProps, IMainPageState> {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      hideBanner: false,
    };
  }

  private handleScroll() {
    const prevScrollpos = 100;
    const currentScrollPos = window.pageYOffset;
    const hide = prevScrollpos < currentScrollPos;
    this.setState({ hideBanner: hide });
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public render() {
    const locale = translation.nb.translation;

    return (
        <div id="wrapper" className="main-page">
          <header id="header" className={ classnames('header', { 'banner-hidden': this.state.hideBanner }) } style={ { backgroundImage: 'url(./images/background_main.jpg)' } }>
            <div className="container">
              <div className="title-block">
                <div className="main-logo">
                  <img src="./images/tidypaygo_black.svg" alt="Logo"/>
                </div>
              </div>
            </div>
          </header>
          <main id="main">
            <div className="container">
                <div className="info-block">
                  <h2>{ locale.infoPage.topBlock.title1 }</h2>
                  <h2>{ locale.infoPage.topBlock.title2 }</h2>
                  <p>{ locale.infoPage.topBlock.text }</p>
                  <ul className="action-block">
                    <li>
                      <a className="btn" href={ process.env.REACT_APP_LINK_BECOME_CLIENT }>{ locale.infoPage.button.becomeClient }</a>
                    </li>
                    <li>
                      <a href={ process.env.REACT_APP_LINK_DEMO }>{ locale.infoPage.button.demo }</a>
                    </li>
                  </ul>
                </div>
              <div className="promo-block">
                <div className="img-wrap">
                  <img src="./images/image.jpg" alt="Image"/>
                </div>
                <h2>{ locale.infoPage.promoBlock.title }</h2>
                <p>{ locale.infoPage.promoBlock.text }</p>
                <a className="btn" href={ process.env.REACT_APP_LINK_CONTACT }>{ locale.infoPage.button.contact }</a>
              </div>
            </div>
          </main>
          <footer id="footer" className="footer">
            <div className="container">
              <div className="logo">
                <img src="./images/tp_white.svg" alt="TP"/>
              </div>
            </div>
            <a className="link-wrap" href={ process.env.REACT_APP_LINK_MAIN } />
          </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hideBanner: state.hideBanner,
});

export default connect(mapStateToProps)(MainPage);
