// User
import {productsPublicRotes} from 'company/routes';

import App from 'core/ui/App';

export const routes = [
  {
    component: App,
    routes: [
      ...productsPublicRotes,
    ],
  },
];
