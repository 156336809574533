import axios from 'axios';
import ApiError from 'company/model/ApiError';

import ApiInfoService from 'company/service/ApiInfoService';

/**
 * Axios instance for accessing the API
 */
const serviceAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // use environment-provided API URL
});

serviceAxios.interceptors.response.use(
    // onFulfulled
    (value) => value,
    // onRejected
    (error) => {
        if (axios.isCancel(error)) {
            console.log('Axios request was canceled. No error.');
            return Promise.reject(ApiError.axiosError(error.response));
        }

        return Promise.reject(ApiError.axiosError(error.response));
    },
);

const ConfiguredApiCompanyService = new ApiInfoService(serviceAxios);

export {
    ConfiguredApiCompanyService as ApiInfoService,
};


