import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-scroll';
import classnames from 'classnames';
import { Link as Hashlink } from 'react-router-dom';
// Models
import Company from 'company/model/Company';

let isSearchFocused = false;

interface IHeaderProps extends React.ClassAttributes<any> {
    companyData: Company;
    isFrontPage: boolean;
    updateFilter: (String) => void;
}

interface IHeaderState extends React.ClassAttributes<any> {
    hideBanner: boolean;
}

class Header extends React.Component<IHeaderProps, IHeaderState> {
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            hideBanner: false,
        };
    }

    private handleScroll() {
        const prevScrollpos = 0;
        const currentScrollPos = window.pageYOffset;
        const hide = prevScrollpos < currentScrollPos;
        this.setState({ hideBanner: hide });
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    public render() {
        const { companyData, isFrontPage, updateFilter } = this.props;
        const merchantQuery = localStorage.getItem('merchantQuery');
        const backgroundImage = companyData && companyData.backgroundImageLocation ? companyData.backgroundImageLocation : './images/background.jpg';
        const scrollSpy = true;
        const scrollSmooth = true;
        const offsetFullHeader = -163; // full header height *(-1)
        const offsetHideHeader = -45; // header height with hidden-banner *(-1)
        return (
            <div>
                { companyData &&
                <header id="header" className={ classnames('header', { 'banner-hidden': this.state.hideBanner }, { 'nav-hidden': !isFrontPage }) } >
                    <div className="header-bg" style={ { backgroundImage: 'url(' + backgroundImage + ')' } } />
                    <div className="container">
                        <div className="title-block">
                            <div className="logo">
                                <img src={ companyData.logoLocation ? companyData.logoLocation : './images/TP.png' } alt="Logo"/>
                            </div>
                            { companyData &&
                                <h1 className="company-title">{ companyData.displayName ? companyData.displayName : companyData.name }</h1>
                            }
                            <div className={ classnames('magnify-box', { 'search-hidden': !isFrontPage})}>
                                <img src="./images/magnify.png" className="magnify-img" onClick={ () => toggleSearchBar() }/>
                                <input type="text" id="magnify" className="magnify-input" onKeyPress={ (event) => handleKeyPress(event.key)} onFocus={ () => searchFocused()} onChange={ (event) => updateFilter(event.target.value)}/>
                                <img src="./images/clear.png" className="magnify-cancel hidden" onClick={ () => this.searchCancelled()}/>

                            </div>
                        </div>
                        <nav>
                            <ul className="main-nav">
                                { companyData && companyData.productGroups.map((productGroupsItem, productGroupsIndex) => (
                                    <li key={ productGroupsIndex }>
                                        <Link to={ 'cathegory-' + productGroupsIndex }
                                              spy={ scrollSpy }
                                              smooth={ scrollSmooth }
                                              offset={ productGroupsIndex === 0 ? offsetFullHeader : offsetHideHeader }
                                              duration={ 400 }>
                                            { productGroupsItem.name }
                                        </Link>
                                    </li>
                                )) }
                            </ul>
                            <div className="btn-wrap">
                                <Hashlink className="btn-back" to={ '/' + merchantQuery }/>
                            </div>
                        </nav>
                    </div>
                </header>
                }
            </div>
        );
    }

    searchCancelled = () => {
        isSearchFocused = false;
        const searchBar = document.querySelector('.magnify-input') as HTMLInputElement;
        searchBar.value = "";
        this.props.updateFilter("");
        toggleSearchBar();
        
    }
}

const mapStateToProps = (state) => ({
    hideBanner: state.hideBanner,
});

const toggleSearchBar = () => {
    if (!isSearchFocused) {
        const magnifyBox = document.querySelector('.magnify-box');
        const searchBar = document.querySelector('.magnify-input');
        const searchImg = document.querySelector('.magnify-img');
        const companyTitle = document.querySelector('.company-title');
        const cancelIcon = document.querySelector('.magnify-cancel');
        const logo = document.querySelector('.logo');
        magnifyBox.classList.toggle('active');
        searchBar.classList.toggle('active');
        searchImg.classList.toggle('active');
        companyTitle.classList.toggle('active');
        cancelIcon.classList.toggle('active');
        cancelIcon.classList.toggle('hidden');
        logo.classList.toggle('active'); 
    }
    
}

const handleKeyPress = (key) => {
    if (key === "Enter") {
        const searchField = document.querySelector('.magnify-input') as HTMLInputElement;
        searchField.blur();
    }
    
}



const searchFocused = () => {
    if (!isSearchFocused) {
        isSearchFocused = true;
    } else {
        isSearchFocused = false;
    }

}

export default connect(mapStateToProps)(Header);
