import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as history from 'history';
import classnames from 'classnames';
// Component
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import ProductsPage from 'components/productsPage/ProductsPage';
import DefaultPage from 'components/defaultPage/DefaultPage';
// Page
import ErrorPage from 'company/ui/ErrorPage';
// Action
import { getCompanyData } from 'company/action/public';
// Model
import Company from 'company/model/Company';
import OrderProduct from 'company/model/OrderProduct';
import Error from 'company/model/Error';
// Services
import * as translation from '../../../locale/translation.json';
import Spinner from 'components/spinner/Spinner';
import Bugsnag from '@bugsnag/js';


interface IBrowsePageProps extends React.ClassAttributes<any> {
  getCompanyData: (query) => void;
  companyData: Company;
  location: history.location;
  history: history;
  totalIncVat: number;
  error: Error;
}

interface IBrowsePageState extends React.ClassAttributes<any> {
  isFrontPage: boolean;
  isLoading: boolean;
  isPopupActive: boolean;
  orderDetails: {
    order: OrderProduct[],
    totalIncVat: number,
    totalExVat: number,
  };
  elInnerHeight: number;
  isPopupsActions: boolean;
  filter: String;
}

class CompanyBrowsePage extends React.Component<IBrowsePageProps, IBrowsePageState> {

  constructor(props) {
    super(props);

    this.state = {
      isFrontPage: true,
      isLoading: false,
      isPopupActive: false,
      orderDetails: {
        order: [],
        totalIncVat: 0,
        totalExVat: 0,
      },
      elInnerHeight: null,
      isPopupsActions: false,
      filter: "",
    };

    this.loaderActivation = this.loaderActivation.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  }

  private loaderActivation(isLoading) {
    this.setState({
      isLoading,
    });
  }

  updateFilter = (searchTerm) => {
    this.setState({filter : searchTerm});
  }

  private togglePopup(state, initialHeight) {
    this.setState({
      isPopupActive: state,
      isPopupsActions: true,
    });
    if (!!initialHeight) {
      this.setState({
        elInnerHeight: initialHeight,
      });
    }
  }

  public componentDidMount() {
    const { getCompanyData, location } = this.props;
    if (location.search || window.location.search) {
      if (location.search && window.location.search) { // redirect to usual url if FB or vipps added additional search param
        const urlWithCorrectSearch = window.location.origin + '/' + window.location.hash + '&' + window.location.search.replace('?', '');
        window.location.replace(urlWithCorrectSearch);
      } else if (location.search) {
        getCompanyData(location.search);
      }
      if (window.location.search) {
        if (window.location.search.includes('?url=')) {
          const searchUrlArr = window.location.search.replace('?', '').split('&');
          const searchUrlString = searchUrlArr.find((item) => item.includes('url='));
          const searchUrl = searchUrlString.split('=')[1];
          const redirectUrl = decodeURIComponent(searchUrl);
          window.location.assign(redirectUrl);
        }
      }
    } else {
      Bugsnag.notify(`Redirect to info page, because location.search = ${ location.search } &  window.location.search = ${ window.location.search } in product page mounting, window.location.href=${window.location.href}`);
      setTimeout(() => {
        this.props.history.push('/info');
      }, 500);
    }
  }

  public componentDidUpdate() {
    if (!!this.state.isPopupsActions) {
      const headerHeight = 163; // full header height
      innerHeight = this.state.elInnerHeight - headerHeight; // - header height to prevent hiding necessary element under header
      window.scrollTo(0, innerHeight);
      this.setState({
        isPopupsActions: null,
      }); // set isPopupsActions as null after closing popup and save position of scroll near image to avoid scrolling on another updates
    }
  }

  public render() {
    const { companyData, totalIncVat, error, history } = this.props;
    let locale = translation.nb.translation;
    if (navigator.language.substr(0, 2).includes("en")) {
        locale = translation['en-GB'].translation;
    } else if (navigator.language.substr(0, 2).includes("fi")) {
      locale = translation['fi'].translation;
    } else if (navigator.language.substr(0, 2).includes("sv")) {
      locale = translation['sv'].translation;
    } else if (navigator.language.substr(0, 2).includes("da")) {
      locale = translation['da'].translation;
    }
    
    const headerHeight = 163; // full header height
    let innerHeight = 0;
    let specificInnerHeight = 0;
    if (!!this.state.isPopupActive) {
      innerHeight = this.state.elInnerHeight - headerHeight; // - header height to prevent hiding necessary element under header
      specificInnerHeight = -1 * (innerHeight); // scroll back on this height, so we need *(-1)
    }
    const specificPos = !!this.state.isPopupActive ? 'fixed' : 'relative';
    return (
      <div>
        { !!companyData
          ? <div id="wrapper" className={ classnames({ 'default-overlay': this.state.isLoading, 'popup-active': this.state.isPopupActive }) }
                 style={ { position: specificPos, top: specificInnerHeight } }
            >
              <Header isFrontPage={ this.state.isFrontPage } companyData={ companyData } updateFilter={this.updateFilter} />
              <ProductsPage togglePopup={ this.togglePopup } filter={ this.state.filter } />
              <Footer history={ history } location={ history.location } btnText={ locale.button.order } totalIncVat={ totalIncVat } companyData={ companyData } loaderActivation={ this.loaderActivation } />

              { this.state.isLoading &&
                <div className="spinner-overlay">
                  <Spinner class="order-is-submit" />
                </div>
              }
            </div>
          : !!error
            ? <ErrorPage history={ history } requestType="merchant" formData={ null } error={ error } />
            : <DefaultPage/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  companyData: state.company.companyData,
  totalIncVat: state.cart.totalIncVat,
  orderDetails: state.orderDetails,
  error: state.company.error,
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getCompanyData,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CompanyBrowsePage);
