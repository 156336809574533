import * as React from 'react';

class Spinner extends React.Component<any> {
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className="loader">Loading...</div>
        );
    }
}
export default Spinner;
