import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as translation from '../../../locale/translation.json';
import CompanyService from 'company/service/CompanyService';
import ApiSimpleService from 'company/service/ApiSimpleService';
import Bugsnag from '@bugsnag/js';
import ReactGA from 'react-ga';
import {bindActionCreators} from 'redux';
import Company from 'company/model/Company';
const currency = require('currency.js');
var LZUTF8 = require('lzutf8');
import * as history from 'history';
import ConfirmationPopup from 'company/ui/ConfirmationPopup';
import { encodeBase64 } from 'lzutf8';

interface IFooterProps extends React.ClassAttributes<any> {
    loaderActivation: (value) => void;
    companyData: Company;
    location: history.location;
    history: history;
    totalIncVat: number;
    btnText: string;
}

interface IFooterState extends React.ClassAttributes<any> {
    isConfirmationPopupOpened: boolean;
    isTimeExpired: boolean;
}

class Footer extends React.Component<IFooterProps, IFooterState> {
    constructor(props) {
        super(props);

        this.state = {
            isConfirmationPopupOpened: false,
            isTimeExpired: false,
        };

        this.goToOrder = this.goToOrder.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.checkExpirationTime = this.checkExpirationTime.bind(this);
        this.getLoginInfo = this.getLoginInfo.bind(this);
    }

    private checkExpirationTime() {
        const merchant = localStorage.getItem('merchantQuery');

        const storedTTL = CompanyService.getStorageDataByItemName(merchant, 'timeExpiration');
        if (+storedTTL <= Date.now()) {
            localStorage.removeItem('shopInfo');
            localStorage.removeItem('orderDetails');
            localStorage.removeItem('userInfo');
            window.location.reload();
            return false;
        } else {
            return true; // true - because time isn't expired
        }
    }

    private goToOrder() {
        const promise = new Promise((resolve, reject) => {
            const merchant = localStorage.getItem('merchantQuery');
            resolve(merchant);
        }).then((merchant) => {
            let merchantEncoded = null;
            if (!!merchant) {
                // @ts-ignore
                merchantEncoded = btoa(merchant.replace('?', ''));
            }
            if (!!merchantEncoded) {
                this.props.history.push(`/order?merchant=${merchantEncoded}`);
            } else {
                this.props.history.push(`/order`);
            }

        }).catch((err) => {
            this.props.history.push(`/order`);
        });
    }

    // Vipps Login should load just when not iOs system or for iOs Safari Browser, in another cases should go to order page immediately
    private canOpenVippsLogin() {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        const isIOs = iosPlatforms.indexOf(platform) > -1;
        const webkit = !!userAgent.match(/WebKit/i);

        // Detect Safari
        const iOSSafari = isIOs && webkit && !userAgent.match(/CriOS/i);
        return !isIOs || iOSSafari;
    }

    private getLoginInfo(successRedirectUrl, test) {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const stage = '/' + process.env.REACT_APP_STAGE;
        const GET_LOGIN_DATA_URL = baseUrl + stage + '/login';
        const merchant = localStorage.getItem('merchantQuery');
        let orderInfo = CompanyService.getStorageDataByItemName(merchant, 'orderDetails');
        orderInfo = JSON.stringify(orderInfo);
        let encodedOrder = LZUTF8.compress(orderInfo);
        encodedOrder = LZUTF8.encodeBase64(encodedOrder);
        //localStorage.clear();
        const loginRedirectUrl = `${ GET_LOGIN_DATA_URL}?successRedirectUrl=${ successRedirectUrl }&test=${ test }&orderInfo=${encodedOrder}`;
        window.location.assign(loginRedirectUrl);
    }

    private goToLogin() {
        const baseUrl = window.location.origin;
        const promise = new Promise((resolve, reject) => {
            const merchantQuery = localStorage.getItem('merchantQuery');
            resolve(merchantQuery);
        }).then((merchantQuery) => {
            let encodedMerchantParams = null;
            if (!!merchantQuery) {
                // @ts-ignore
                const merchant = btoa(merchantQuery.replace('?', ''));
                encodedMerchantParams = encodeURIComponent(`merchant=${merchant}`);
            }
            const successRedirectUrl = encodedMerchantParams ? encodeURIComponent(`${baseUrl}/#/order?${encodedMerchantParams}`) : encodeURIComponent(`${baseUrl}/#/order`);
            const isTest = process.env.REACT_APP_IS_LOGIN_TEST; // false =for real vipps, true - created fake userInfo data for testing
            const isLoading = true; // activate preloader, while login request sends
            this.props.loaderActivation(isLoading);

            this.getLoginInfo(successRedirectUrl, isTest);
        }).catch((err) => {
            const successRedirectUrl = encodeURIComponent(`${baseUrl}/#/order`);
            const isTest = process.env.REACT_APP_IS_LOGIN_TEST; // false =for real vipps, true - created fake userInfo data for testing
            const isLoading = true; // activate preloader, while login request sends
            this.props.loaderActivation(isLoading);

            this.getLoginInfo(successRedirectUrl, isTest);
        });
    }

    private toggleConfirmationPopup() {
        new Promise((resolve) => {
            const merchant = localStorage.getItem('merchantQuery');
            let isNorway = false;
            const shopInfo = CompanyService.getStorageDataByItemName(merchant, 'shopInfo');
            const userInfo = CompanyService.getStorageDataByItemName(merchant, 'userInfo');
            const isTimeNotExpired = this.checkExpirationTime();
            try {
                isNorway = merchant.includes("countryCode=NO") ? true : false;
            } catch(error) {
                console.log("ERROR checking if merchant has countryCode: " + error);
                //Bugsnag.notify(`Error checking if merchant includes countryCode. Localstorage Merchant: ${ merchant }, Error: ${ error }`);
            }
            
            //const isVippsLoginCanBeOpened = this.canOpenVippsLogin();
            if (!!isTimeNotExpired && !userInfo && !!isNorway && !shopInfo.skipLogin) {
                resolve();
            } else {
                const merchant = btoa(localStorage.getItem('merchantQuery').replace('?', ''));
                this.props.history.push(`/order?merchant=${merchant}`);
            }
        })
        .then(() => {
            this.setState({
                isConfirmationPopupOpened:  !this.state.isConfirmationPopupOpened,
            });
        });
    }

    public render() {
        if (window.location.href.indexOf("loggedIn") != -1) {
            const merchant = btoa(localStorage.getItem('merchantQuery').replace('?', ''));
            this.props.history.push(`/order?merchant=${merchant}`);
        } else {
            localStorage.removeItem('currentOrder');
        }
        const { companyData, totalIncVat, btnText, location } = this.props;
        let locale = translation.nb.translation;
        try {
            if (navigator.language.substr(0, 2).includes("en")) {
                locale = translation['en-GB'].translation;
            } else if (navigator.language.substr(0, 2).includes("fi")) {
                locale = translation['fi'].translation;
            } else if (navigator.language.substr(0, 2).includes("sv")) {
                locale = translation['sv'].translation;
            } else if (navigator.language.substr(0, 2).includes("da")) {
                locale = translation['da'].translation;
            }
        } catch(err) {
            console.log("Error setting translation: " + err);
        }
        
        let total = totalIncVat;
        if (totalIncVat < 0) {
            total = 0;
        }

        let actionButton;
        let link;
        if (location && location.pathname.indexOf('/order') > -1) {
            actionButton = <button type="submit" form="order"> { btnText } </button>;
            link = <a className="tac" href={ process.env.REACT_APP_LINK_TAC } target="_blank">{ locale.tac }</a>;
        } else {
            actionButton = <button className={ classnames('action btn', { disable: totalIncVat <= 0 }) } onClick={ () => { this.toggleConfirmationPopup();
             } } > { btnText } </button>;
        }
        let shortCurrency = "";
        /* if (navigator.language.substr(0, 2).includes("en")) {
            shortCurrency = "GBP";
        } */

        return (
            <footer id="footer" className="footer">
                { companyData &&
                    <div className="container">
                        <div className="logo">
                            <img src="./images/tidypay-log-white-transparent.png" alt="Logo"/>
                        </div>
                        <div className="total-price">{shortCurrency} { currency(total).value.toFixed(2) }</div>
                        <div className="link-wrap">
                            { actionButton }
                            { link }
                        </div>
                    </div>
                }
                { this.state.isConfirmationPopupOpened &&
                    <div>
                        <ConfirmationPopup goToOrder={ this.goToOrder } goToLogin={ this.goToLogin } />
                    </div>
                }
            </footer>
        );
    }
}

export default Footer;
