import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import company from 'company/reducers/companyReducer';
import cart from 'company/reducers/cartReducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  company,
  cart,
});

export default rootReducer;
