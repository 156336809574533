import update from 'immutability-helper';
// Constants
import { RECEIVE_COMPANY, RECEIVE_ERROR } from 'company/constants';
// Model
import Company from 'company/model/Company';
import Error from 'company/model/Error';
import CompanyService from 'company/service/CompanyService';

const initialState: {
    companyData: Company,
    error: Error,
} = {
    companyData: null,
    error: null,
};

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_COMPANY: {
            const { company } = action.payload;
            if (company) {
                const merchant = company.deptIdent
                    ? `?countryCode=${ company.countryCode }&companyIdent=${ company.companyIdent }&deptIdent=${ company.deptIdent }`
                    : `?countryCode=${ company.countryCode }&companyIdent=${ company.companyIdent }`;

                localStorage.setItem('merchantQuery', merchant);
                CompanyService.setStorageShopInfo(merchant, company);

            }
            return update(state, {
                companyData: { $set: company },
            });
        }
        case RECEIVE_ERROR: {
            const { error } = action.payload;
            return update(state, {
                error: { $set: error },
            });
        }
        default:
            return state;
    }
};

export default companyReducer;
