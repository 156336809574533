import * as React from 'react';
import * as history from 'history';
import Error from 'company/model/Error';
import CompanyService from 'company/service/CompanyService';
import * as translation from '../../../locale/translation.json';

interface IErrorProps extends React.ClassAttributes<any> {
    error: Error;
    requestType: string;
    formData: object;
    history: history;
}

interface IErrorState extends React.ClassAttributes<any> {
    setIsOpenModal: boolean;
    keyCode: number;
    ctrlKey: boolean;
}

class ErrorPage extends React.Component<IErrorProps, IErrorState> {
    constructor(props) {
        super(props);
        this.state = {
            setIsOpenModal: false,
            keyCode: null,
            ctrlKey: false,
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    private goToFrontPage() {
        // front-page is MainPage component
        this.props.history.push('/info');
    }

    private resendTransaction() {
        window.location.reload();
    }

    private toggleModal() {
        if (!this.state.setIsOpenModal && !this.state.keyCode && !this.state.ctrlKey) {
            return;
        }
        this.setState({
            setIsOpenModal:  !this.state.setIsOpenModal,
            keyCode: null,
            ctrlKey: false,
        });

    }

    private handleKeyDown(e) {
        if (e.keyCode === 73 && !!e.ctrlKey) {
            this.setState({
                keyCode: e.keyCode,
                ctrlKey: e.ctrlKey,
            });
        }
    }

    public componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    public render() {
        const { error, requestType, history } = this.props;
        const locale = translation.nb.translation;
        let errorMessage = null;
        if (!!error && error.userMessage) {
            errorMessage = error.userMessage;
        } else if (history && history.location && history.location.state && history.location.state.error && history.location.state.error.userMessage) {
            errorMessage = history.location.state.error.userMessage;
        } else {
            errorMessage = locale.message.failed.smthWrong;
        }
        let actionButton;
        if (requestType === 'merchant') {
            actionButton = <button className="action btn" onClick={ () => this.goToFrontPage() }> { locale.button.back }</button>;
        } else if (requestType === 'transaction') {
            actionButton = <button className="action btn" onClick={ () => this.resendTransaction() }>{ locale.button.again }</button>;
        } else {
            actionButton = <button className="action btn" onClick={ () => history.goBack() }>{ locale.button.back }</button>;
        }
        return (
            <div className="default-page-wrap error-page">
                <div className="text-block">
                    <div id="modal-opener" className="cross-icon" onClick={ () => this.toggleModal() } />
                    <h1>{ locale.title.error }</h1>
                    <p>
                        { !!errorMessage &&
                            errorMessage
                        }
                    </p>
                    { actionButton  ?? actionButton }
                </div>

                { this.state.setIsOpenModal && error &&
                    < div className="modal">
                        <button className="cross-icon" onClick={ () => this.toggleModal() } />
                        <p>{ error.status }</p>
                        <p>{ error.errorCode }</p>
                        <p>{ error.message }</p>
                        <p>{ error.userMessage }</p>
                        <p>{ error.developerMessage }</p>
                        <p>{ error.moreInfo }</p>
                    </div>
                }
            </div>
        );
    }
}

export default ErrorPage;
