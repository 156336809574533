
class ApiError {
    public status: number;
    public errorCode: number;
    public message: string;
    public userMessage: string;
    public developerMessage: string;
    public moreInfo: string;
    public isError: boolean;

    public static axiosError(err): ApiError {
        const ae = new ApiError();
        if (err && err.data) {
            ae.status = err.data.httpStatusCode ? err.data.httpStatusCode : err.data.status;
            ae.errorCode = err.data.errorCode;
            ae.userMessage = err.data.userMessage;
            ae.message = err.data.message;
            ae.developerMessage = err.data.developerMessage;
            ae.moreInfo = err.data.moreInfo;
        } else {
            ae.status = -1;
            ae.message = 'Unknown error';
        }
        return ae;
    }
}

export default ApiError;
