// Constants
import { RECEIVE_COMPANY, RECEIVE_ERROR, RECEIVE_STORED_PRODUCT, SUB_QUANTITY, ADD_QUANTITY, UPDATE_PRODUCT, REMOVE_FROM_CART, ADD_COMMENT } from 'company/constants';

// Model
import Company from 'company/model/Company';
import OrderDetails from 'company/model/OrderDetails';
import OrderProduct from 'company/model/OrderProduct';
// Service
import CompanyService from 'company/service/CompanyService';
import { ApiInfoService } from 'company/service';
import ReactGA from 'react-ga';
import Bugsnag from '@bugsnag/js';

const receiveCompanyAction = (company: Company) =>  ({
    type: RECEIVE_COMPANY,
    payload: { company },
});

const receiveError = (error: Error) =>  ({
    type: RECEIVE_ERROR,
    payload: { error },
});

const receiveStoredProductAction = (defaultStateProductList: OrderProduct[]) =>  ({
    type: RECEIVE_STORED_PRODUCT,
    defaultStateProductList,
});

export const getCompanyData = (query) => (dispatch) => {
    if (!query) {
        window.location.assign('/#/info');
        Bugsnag.notify(`Redirect from merchant page to info page, because merchant = ${query}`);
        return;
    }
    return ApiInfoService.getCompany(query)
        .then((company) => {

            if(company == null){
                Bugsnag.notify(`getCompany results in null value`);
            } else {
                if(company.countryCode == null || company.companyIdent == null){
                    Bugsnag.notify(`countryCode and/or companyIdent is null`);
                }
            }
            const merchant = company.deptIdent
                ? `?countryCode=${ company.countryCode }&companyIdent=${ company.companyIdent }&deptIdent=${ company.deptIdent }`
                : `?countryCode=${ company.countryCode }&companyIdent=${ company.companyIdent }`;

            const now = Date.now();
            const ttlMin = process.env.REACT_APP_LOCAL_STORAGE_TTL_IN_MIN ? +process.env.REACT_APP_LOCAL_STORAGE_TTL_IN_MIN : 15;
            const ttl = ttlMin * 60 * 1000; // convert min to ms
            const timeExpiration = (now + ttl);

            // set to storage ttl for current company merchant
            const storedTTL = localStorage.getItem('timeExpiration') ? JSON.parse(localStorage.getItem('timeExpiration')) : {};
            if (+storedTTL[merchant] <= Date.now()) {
                localStorage.removeItem('shopInfo');
                localStorage.removeItem('orderDetails');
                localStorage.removeItem('userInfo');
            }

            // redefine TTL for current merchant
            storedTTL[merchant] = timeExpiration;
            localStorage.setItem('timeExpiration', JSON.stringify(storedTTL));
            const orderDetails = CompanyService.getStorageDataByItemName(query, 'orderDetails');
            return { company, orderDetails };
        })
        .then((data: { company: Company, orderDetails: OrderDetails }) => {
            const company = data.company;
            const defaultStateProductList = !!data.orderDetails ? CompanyService.getConcatProductsArrayAndOrder(company, data.orderDetails.order) : CompanyService.getProductsArray(company);
            return { company, defaultStateProductList };
        })
        .then((data: { company: Company, defaultStateProductList: OrderProduct[] }) => {
            dispatch(receiveCompanyAction(data.company));
            dispatch(receiveStoredProductAction(data.defaultStateProductList));
            const merchant = data.company.deptIdent
                ? `?countryCode=${ data.company.countryCode }&companyIdent=${ data.company.companyIdent }&deptIdent=${ data.company.deptIdent }`
                : `?countryCode=${ data.company.countryCode }&companyIdent=${ data.company.companyIdent }`;
            ReactGA.event({
                category: 'merchant',
                action: 'get_request',
                label: merchant,
            });
        })
        .catch((err) => {
            dispatch(receiveError(err));
            ReactGA.exception({
                description: 'An_error_occurred_in_get_merchant_request',
                fatal: true,
            });
            Bugsnag.addMetadata('response', {
                json: JSON.stringify(err),
            });
            const merchant = localStorage.getItem('merchantQuery');
            Bugsnag.notify(`Merchant failed, errorCode: ${ err.status }, message: ${ err.message }, localStorage Merchant: ${ merchant }`);

        });
};

// subtract qt action
export const subtractQuantity = (id) => {
    return {
        type: SUB_QUANTITY,
        id,
    };
};

// add qt action
export const addQuantity = (id) => {
    return {
        type: ADD_QUANTITY,
        id,
    };
};

// update qt action
export const updateProduct = (id, price) => {
    return {
        type: UPDATE_PRODUCT,
        id,
        price,
    };
};

// remove qt action
export const removeFromCart = (id) => {
    return {
        type: REMOVE_FROM_CART,
        id,
    };
};

// add comment action
export const addCommentAction = (comment, id) => {
    return {
        type: ADD_COMMENT,
        comment,
        id,
    };
};
